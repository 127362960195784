import {
  type AnalyticsBrowser,
  type EventProperties,
} from '@segment/analytics-next';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import { useMyOrgId } from '../components/Organization/hooks/organization';
import { useAnalytics } from './AnalyticsContext';

class OneTimePurchaseAnalytics {
  constructor(
    private readonly analytics: AnalyticsBrowser,
    readonly orgId: string | null
  ) {}

  trackEventPurchaseStarted(props: EventProperties) {
    this.analytics.track('Event Purchase Started', this.buildEventProps(props));
  }

  trackEventPurchasePurchaseOptionViewed(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Purchase Options Viewed',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseTestimonialVideoViewed(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Testimonial Video Viewed',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseOptionChosen(props: EventProperties) {
    this.analytics.track(
      'Event Purchase Purchase Option Chosen',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseConfirmationButtonClicked(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Confirmation Button Clicked',
      this.buildEventProps(props)
    );
  }

  trackEventPostPurchaseUpsellViewed(props: EventProperties) {
    this.analytics.track(
      'Event Post Purchase Upsell Viewed',
      this.buildEventProps(props)
    );
  }

  trackEventPostPurchaseUpsellDeclined(props: EventProperties) {
    this.analytics.track(
      'Event Post Purchase Upsell Declined',
      this.buildEventProps(props)
    );
  }

  trackEventPostPurchaseUpsellAccepted(props: EventProperties) {
    this.analytics.track(
      'Event Post Purchase Upsell Accepted',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseModalViewed(
    props: { type: 'apply-discount' | 'contact-sales' } & EventProperties
  ) {
    this.analytics.track(
      'Event Purchase Modal Viewed',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseContactSalesClicked(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Contact Sales Clicked',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseW9Downloaded(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase W9 Downloaded',
      this.buildEventProps(props)
    );
  }

  trackEventPurchasePayOptionClicked(
    option: 'card' | 'invoice',
    props?: EventProperties
  ) {
    this.analytics.track(
      `Event Purchase ${capitalize(option)} Tab Clicked`,
      this.buildEventProps(props)
    );
  }

  trackEventPurchasePaymentSubmitted(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Payment Submitted',
      this.buildEventProps(props)
    );
  }

  trackEventPurchasePaymentFailed(props?: EventProperties) {
    this.analytics.track(
      'Event Purchase Payment Failed',
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseSendInvoiceClicked(props?: EventProperties) {
    this.analytics.track(
      `Event Purchase Send Invoice Clicked`,
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseEventPlanningCallSkipped(props?: EventProperties) {
    this.analytics.track(
      `Event Purchase Event Planning Call Skipped`,
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseEventPlanningCallScheduled(props?: EventProperties) {
    this.analytics.track(
      `Event Purchase Event Planning Call Scheduled`,
      this.buildEventProps(props)
    );
  }

  trackEventPurchasePreCallScheduled(props?: EventProperties) {
    this.analytics.track(
      `Event Purchase Pre Call Scheduled`,
      this.buildEventProps(props)
    );
  }

  trackEventPurchaseHeadcountCallScheduled(props?: EventProperties) {
    this.analytics.track(
      `Event Purchase Headcount Call Scheduled`,
      this.buildEventProps(props)
    );
  }

  private buildEventProps(props?: EventProperties): EventProperties {
    return {
      ...props,
      orgId: this.orgId,
    };
  }
}

export function useOneTimePurchaseAnalytics(): OneTimePurchaseAnalytics {
  const analytics = useAnalytics();
  const orgId = useMyOrgId();

  return useMemo(
    () => new OneTimePurchaseAnalytics(analytics, orgId),
    [analytics, orgId]
  );
}
